// extracted by mini-css-extract-plugin
export var chatAvatar = "InterviewItem-module--chat-avatar--VwLEU";
export var chatContainer = "InterviewItem-module--chat-container--zjvho";
export var chatLeft = "InterviewItem-module--chat-left--+YUQT";
export var chatRight = "InterviewItem-module--chat-right--StqH2";
export var chatText = "InterviewItem-module--chat-text--coPZL";
export var gatsbyRespImageBackgroundImage = "InterviewItem-module--gatsby-resp-image-background-image--IgYOG";
export var gatsbyRespImageImage = "InterviewItem-module--gatsby-resp-image-image--xHRp7";
export var gatsbyRespImageWrapper = "InterviewItem-module--gatsby-resp-image-wrapper--Cq01Q";
export var mainContent = "InterviewItem-module--main-content--CYk1J";
export var readMorePosts = "InterviewItem-module--read-more-posts--1QDCH";